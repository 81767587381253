export const environment = {
	production: true,
	apiUrl: 'https://api.jumanmarket.com',
	webUrl: "https://www.jumanmarket.com",
	rsa_public_key: "$2a$10$e.oPc.dyrwRoQCpDvO9Rhe",
	supplierUrl: 'https://supplier.jumanmarket.com/login',
	appversion: "6.0", //app version
	firebaseConfig: {
		apiKey: "AIzaSyDqMUhJkuJbnrex7CNRWF32tOolh7HmW6c",
		authDomain: "juman-marketplace-production.firebaseapp.com",
		projectId: "juman-marketplace-production",
		storageBucket: "juman-marketplace-production.appspot.com",
		messagingSenderId: "881809589217",
		appId: "1:881809589217:web:b4fd5414d1451cbb9b08a0",
		measurementId: "G-8HKRLMDL82"
	},
	SENTRY_AUTH_TOKEN: "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"
};